@import 'styles/libs';
@import 'styles/texts';

.layout {
  height: 100vh; /* dvhが使えないブラウザでのフォールバック用 */
  height: calc-dvh(100); /* iOSなどでアドレスバーで隠れる領域を考慮した最大の高さ指定 */
}

.base {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: var(--gray5);

  @include isSp {
    padding: 16px;
  }
}

.container {
  width: 760px;
  padding: 0 24px;
  text-align: center;

  @include isSp {
    width: 100%;
    height: 100%;
  }
}

.topContainer {
  padding: 24px 0;
}

.statusCode {
  @include typography('normal', 120px, bold);

  color: var(--primary-blue50);

  @include isSp {
    font-size: 60px;
  }
}

.bottomContainer {
  padding: 32px 0;
}

.summary {
  @include typography('normal', 20px, bold);

  color: var(--gray100);

  @include isSp {
    font-size: 18px;
  }
}

.description {
  @include typography('normal', 14px, normal);

  margin-top: 16px;
  color: var(--gray60-alpha);

  @include isSp {
    @include typography('normal', 12px, normal);
  }
}

.buttonWrapper {
  margin-top: 32px;
}
