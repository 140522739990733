// NOTE: map 関数を使う為に @use で呼び出します。
@use 'sass:map';

$line-heights: (
  'comfort': var(--comfort-line-height),
  'normal': var(--normal-line-height),
  'tight': var(--tight-line-height),
  'dense': var(--dense-line-height),
);
$font-sizes: 10px, 12px, 14px, 16px, 18px, 20px, 24px, 32px, 48px, 120px;
$font-weights: normal, bold;

@mixin typography($line-height: 'normal', $font-size: 14px, $font-weight: normal) {
  @if map.has-key($line-heights, $line-height) {
    line-height: map.get($line-heights, $line-height);
  }

  @else {
    @error '$line-height のキーを正しく指定してください';
  }

  @if index($font-sizes, $font-size) {
    font-size: $font-size;
  }

  @else {
    @error '$font-size の数値は $font-sizes 内の数値のみで指定してください';
  }

  @if index($font-weights, $font-weight) {
    font-weight: $font-weight;
  }

  @else {
    @error '$font-weight の数値は $font-weights 内の数値のみで指定してください';
  }
}
