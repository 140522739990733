$sp-breakpoint: 599px;
$tablet-breakpoint: 1024px;

@mixin isSp {
  @media screen and (max-width: $sp-breakpoint) {
    @content;
  }
}

@mixin isTablet {
  @media screen and (min-width: $sp-breakpoint + 1px) and (max-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin isPc {
  @media screen and (min-width: $tablet-breakpoint + 1px) {
    @content;
  }
}

// 格納場所を別にしてもよさそうだが
@function calc-dvh($heightValue) {
  // --dvh-unit は 1dvh or 1vh が入る
  @return calc(#{$heightValue} * var(--dvh-unit));
}
