@import 'styles/libs';

:root {
  // Colors
  // Primary
  --primary-blue70: #1b2cc9;
  --primary-blue60: #1f3eec;
  --primary-blue50: #4e5ef2;
  --primary-blue20: #dfe0ff;
  --primary-blue5: #f6f7fe;
  // Secondary
  --secondary-green60: #219273;
  --secondary-green50: #36a286;
  // Gray
  --gray100: #08151a;
  --gray30: #b5b9ba;
  --gray20: #cdd0d1;
  --gray10: #e2e3e3;
  --gray5: #f3f3f4;
  // Gray Alpha
  --gray90-alpha: rgba(8, 21, 26, 0.9);
  --gray80-alpha: rgba(8, 21, 26, 0.8);
  --gray60-alpha: rgba(8, 21, 26, 0.6);
  --gray20-alpha: rgba(8, 21, 26, 0.19);
  --gray10-alpha: rgba(8, 21, 26, 0.12);
  // White
  --white100: #ffffff;
  --white60: #92989a;
  // Red
  --red100: #b32200;
  --red60: #a51f00; // TODO: 本来、red100-darken が正式名称なので、後で修正する
  --red50: #b32200; // TODO: 本来、red100 が正しいので、後で修正する
  --red5: #fbf4f2;
  // Green
  --green100: #007d53;
  --green5: #f2fbf8;
  // Link
  --link-blue: #2264cd;
  // Focus
  --focus-blue-alpha: rgba(65, 101, 173, 0.86);
  // Accent
  --accent: #1aabf5;
  --accent-purple: #7f3ecd;

  // Shadow
  --elevation16: 0 2px 16px rgba(0, 0, 0, 0.08);
  --main-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);

  // z-index
  --z-index-base: 10;
  --z-index-side-menu: var(--z-index-base);
  --z-index-card: calc(var(--z-index-base) * 10);
  --z-index-popover: var(--z-index-card);
  --z-index-dropdown: calc(var(--z-index-card) * 10);
  --z-index-navigation-sp: calc(var(--z-index-dropdown) * 10);
  --z-index-side-menu-sp: calc(var(--z-index-navigation-sp) * 10);
  --z-index-modeless-window: calc(var(--z-index-side-menu-sp) * 10);
  --z-index-modal: calc(var(--z-index-modeless-window) * 10);
  --z-index-tooltip: calc(var(--z-index-modal) * 10);
  --z-index-toast: calc(var(--z-index-tooltip) * 10);
  --z-index-scrim: calc(var(--z-index-toast) * 10);
  // NOTE: Picker系のコンポーネントが z-index: 5 を持っている為、Pickerと重なる場合に用いる
  --z-index-pickerOver: 6;

  // font-family
  // ※ -apple-system は Webkit レンダリングエンジン用の記述。 'BlinkMacSystemFont' は Blink レンダリングエンジン用の記述で、それぞれシステムフォントを読み込ませる指定です。
  // ※ Noto Sans JP は現時点ではインポート対応しておらず、font-family に記載だけしています。必要であればインポート対応してください。
  --font-family: -apple-system, 'BlinkMacSystemFont', 'Hiragino Kaku Gothic Pro', 'Noto Sans JP', sans-serif;

  // line-height
  --comfort-line-height: 170%;
  --normal-line-height: 150%;
  --tight-line-height: 125%;
  --dense-line-height: 100%;

  // MainLayout
  --navigation-height: 44px;

  @include isSp {
    --navigation-height: 96px;
  }

  --footer-height: 56px;

  @include isSp {
    --footer-height: 105px;
  }

  // 普段は、1dvh === 1dvh となる。
  // しかし、 Safari の特定バージョンでうまく動作しないため、1dvh === 1vh に扱うことがある。
  --dvh-unit: 1dvh;
}

html,
body {
  font-family: var(--font-family);
  line-height: var(--normal-line-height);
  color: var(--gray100);
  letter-spacing: 0.04em;
  word-break: break-all;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h1,
h2,
h3 {
  font-weight: bold;
}

a {
  color: var(--primary-blue50);

  &:hover {
    text-decoration: underline;
  }
}

// NOTE: destyle.css でリセットがかからない項目なので、ここで指定する
input,
textarea,
select,
button {
  letter-spacing: 0.04em;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

// iOS Safari ネイティブの time picker
input[type='time']::-webkit-date-and-time-value {
  text-align: left;
}
