@import 'styles/libs';
@import 'styles/texts';

.base {
  position: fixed;
  bottom: 40px;
  left: 40px;
  z-index: var(--z-index-toast);
  display: flex;
  align-items: center;
  width: 415px;
  min-height: 56px;
  padding: 18px 18px 18px 16px;
  background: var(--white100);
  border-radius: 4px;
  box-shadow: var(--elevation16);

  @include isSp {
    bottom: 16px;
    left: 50%;
    width: calc(100% - 16px * 2);
    transform: translateX(-50%);
  }

  &::before {
    position: absolute;
    left: 0;
    width: 4px;
    height: 100%;
    content: '';
    border-radius: 4px 0 0 4px;
  }

  &.error::before {
    background: var(--red50);
  }

  &.success::before {
    background: var(--green100);
  }
}

.severityIconWrapper {
  width: 24px;
  height: 24px;
  margin-right: 8px;

  svg {
    &.error {
      fill: var(--red50);
    }

    &.success {
      fill: var(--green100);
    }
  }
}

.message {
  @include typography('normal', 14px, bold);

  margin-right: 14px;
  color: var(--gray100);
  white-space: pre-line;
}

.closeIconWrapper {
  width: 16px;
  height: 16px;
  margin-left: auto;

  svg {
    font-size: 16px;
    fill: var(--gray60-alpha);
  }
}
