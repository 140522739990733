@import 'styles/texts';
@import 'styles/three-point-reader';

.base {
  position: relative;
  z-index: var(--z-index-side-menu);
  display: flex;
  flex-direction: column;
  width: 190px;
  height: 100%;
  padding: 0 4px;
  overflow-y: auto;
  background: var(--gray100);
  transition: width 0.2s ease;
  scrollbar-width: none;

  --webkit-transition: width 0.2s ease;

  &.collapsed {
    width: 53px;
  }
}

.logoSection {
  position: sticky;
  top: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0;
  background: var(--gray100);

  &.collapsed {
    justify-content: center;
  }
}

.logo {
  width: 133px;
  height: 45px;
  padding-top: 9px;
  padding-left: 14px;
}

.toggleButton {
  display: flex;
  padding: 12px;
}

.menuItemSection {
  flex-grow: 1; // 画面の高さが内包要素より大きい場合にこのセクションを伸ばすための指定
}

.menuItem {
  @include typography('tight', 12px, normal);

  display: flex;
  column-gap: 8px;
  align-items: center;
  padding: 12px 14px;
  color: var(--white60);
  white-space: nowrap; // アニメーション中にテキストが折り返されないようにする

  &:hover,
  &:focus {
    color: var(--white100);
    text-decoration: none;
  }

  &.active {
    @include typography('tight', 12px, bold);

    color: var(--white100);
  }

  &.collapsed {
    padding: 12px;
  }
}

.supportLinkSection {
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background: var(--gray100);
}

.supportLink {
  @include typography('tight', 12px, normal);

  display: flex;
  padding: 12px 14px;
  color: var(--gray5);
  white-space: nowrap; // アニメーション中にテキストが折り返されないようにする

  &:hover,
  &:focus {
    color: var(--white100);
  }

  &.collapsed {
    padding: 12px;
    color: var(--white60);

    &:hover,
    &:focus {
      color: var(--white100);
    }
  }
}
