@import 'styles/texts';
@import 'styles/libs';

.base {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--footer-height);
  padding: 16px 40px;
  background: var(--gray5);
  border-top: 1px solid var(--gray20);

  @include isSp {
    flex-direction: column;
    row-gap: 8px;
    justify-content: center;
    padding: 16px;
  }
}

.policies {
  display: flex;
  column-gap: 16px;
  align-items: center;

  @include isSp {
    flex-direction: column;
    row-gap: 8px;
  }
}

.policyLink {
  color: var(--gray60-alpha);
}

.corporate {
  display: flex;
  align-items: center;
}

svg.icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  color: var(--gray60-alpha);

  @include isSp {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
}

.text {
  @include typography('normal', 12px);

  color: var(--gray60-alpha);
}

.link {
  @include typography('normal', 12px);
}

.marginRight {
  margin-right: 8px;

  @include isSp {
    margin-right: 2px;
  }
}
