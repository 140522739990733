@import 'styles/libs';

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 68px;
  padding: 0 160px;
  background: var(--gray100);

  @include isSp {
    padding: 0 16px;
  }
}

.logo {
  height: 28px;
}
