.container {
  position: relative;
  display: inline-block;
  line-height: 1;
}

.cardWrapper {
  position: absolute;
  z-index: var(--z-index-popover);
  min-width: max-content;
  margin-top: 4px;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
}
