@import 'styles/libs';
@import 'styles/texts';

.base {
  display: grid;
  grid-template-areas:
    'sm nav'
    'sm conFot';
  grid-template-rows: var(--navigation-height) 1fr;
  grid-template-columns: auto 1fr;
  height: 100vh; /* dvhが使えないブラウザでのフォールバック用 */
  height: calc-dvh(100); /* iOSなどでアドレスバーで隠れる領域を考慮した最大の高さ指定 */

  @include isSp {
    grid-template-areas: 'nav' 'conFot';
    grid-template-columns: 1fr;
    height: unset;
    min-height: 100vh; /* dvhが使えないブラウザでのフォールバック用 */
    min-height: calc-dvh(100); /* iOSなどでアドレスバーで隠れる領域を考慮した最大の高さ指定 */
  }

  &.scrollableHeader {
    grid-template-areas:
      'sm conFot'
      'sm conFot';
  }
}

.sideMenu {
  grid-area: sm;

  @include isSp {
    grid-area: unset;
  }
}

.navigation {
  grid-area: nav;
  height: 44px;

  @include isSp {
    position: sticky;
    top: 0;
    z-index: var(--z-index-navigation-sp);
  }
}

.scrollContainer {
  grid-area: conFot;
  overflow-y: scroll;
  isolation: isolate;
}

.contentFooterContainer {
  min-width: fit-content;
  height: 100%;
}

.content {
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: calc(100% - var(--footer-height));
  padding: 24px 40px;
  background: var(--gray5);

  @include isSp {
    padding: 16px;
  }
}

.notCompatibleAlert {
  @include typography('normal', 14px, normal);

  display: flex;
  column-gap: 12px;
  padding: 16px;
  margin-bottom: 16px;
  color: var(--red50);
  background-color: var(--white100);
  border-radius: 4px;
  box-shadow: var(--elevation16);
}

.contentNoPadding {
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: calc(100% - var(--footer-height));
  background: var(--gray5);

  .notCompatibleAlert {
    margin: 16px;
  }
}
