// NOTE: この @base-color の数値は、ホバー色や submit ボタン等にも影響があり、 CSS 変数だと正しく機能しない為、一旦直打ちで対応
// TODO: @base-color を CSS 変数に変える
@base-color: #4e5ef2;
@hover-color: #f6f7fe;
@hover-bg-color: #dfe0ff;
@border-color: rgba(8, 21, 26, 0.19);
@font-color: var(--gray100);
@sub-font-color: var(--gray60-alpha);
@disable-font-color: var(--gray20-alpha);

@font-size-base: 16px;
@line-height-base: 20px;
@border-radius-base: 4px;

@B200: @border-color;
@B400: @disable-font-color;
@B600: @sub-font-color;
@B800: @font-color;
@H050: @hover-color;
@H100: @hover-bg-color;
@H500: @base-color;
@H600: @base-color;
@H700: @base-color;
@text-color: @font-color;

// 個別にスタイルを当てておきたいもの
.rs-picker-popup {
  z-index: calc(var(--z-index-modal) + 1);
}

.rs-calendar-month-view .rs-calendar-header-title-date {
  color: var(--gray100);
  font-size: 16px;
}

.rs-calendar-header-title.rs-calendar-header-title-date {
  font-size: 16px;
  color: var(--gray100) !important;
}

.rs-calendar-month-dropdown-cell-active
  > .rs-calendar-month-dropdown-cell-content:hover {
  color: var(--white100) !important;
  background-color: var(--primary-blue50) !important;
}

.rs-calendar-month-dropdown-row-wrapper {
  overflow-x: hidden !important;
}

.rs-calendar-month-dropdown-cell-content {
  font-size: 14px !important;
}

.rs-calendar-month-dropdown-cell:not(
    .rs-calendar-month-dropdown-cell-active
  ).disabled
  .rs-calendar-month-dropdown-cell-content {
  color: var(--gray20-alpha) !important; // 指定できない日付の文字色
}

// SP時などcaretAsを上書きして右側のアイコンを非表示の際の余白を削除
.rs-picker-date .rs-picker-toggle.rs-btn:not(:has(.rs-picker-toggle-caret)) {
  padding-right: 0;
}

.rs-picker-toggle {
  padding: 0;
  font-size: 16px;
  line-height: var(--tight-line-height);
}

.rs-picker-toggle-value {
  font-size: 16px;
  color: var(--gray100) !important;
}

.rs-picker-toggle-caret {
  top: 1px !important;
  right: 0 !important;
  color: var(--gray100) !important;
}

.rs-picker-toggle-textbox {
  padding-left: 0 !important;
  font-size: 16px;

  &::placeholder {
    color: var(--gray20-alpha) !important;
  }
}

.rs-picker-toggle-placeholder {
  font-size: 16px;
  color: var(--gray20-alpha) !important;
}

.rs-picker-subtle.rs-picker-disabled
  .rs-picker-toggle
  .rs-picker-toggle-wrapper {
  cursor: pointer;
}

.rs-input-group {
  cursor: pointer;
}

.rs-picker-date.rs-picker-subtle .rs-input-group > input {
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  &::placeholder {
    color: var(--gray20-alpha);
  }
}

.rs-picker-date.rs-picker-subtle .rs-input-group {
  height: 24px;
  outline: none;
  background-color: transparent;
}

.rs-picker-date.rs-picker-subtle .rs-input-group:hover {
  background-color: var(--gray5);
}

.rs-picker-date.rs-picker-subtle .rs-input-group:active {
  background-color: var(--gray20-alpha);
}

.rs-picker:not(.rs-picker-disabled):hover {
  border-color: var(--gray20-alpha);
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  box-shadow: none;
}

.rs-picker-toggle-read-only {
  cursor: pointer;
}

.rs-picker-daterange.rs-picker-subtle .rs-input-group > input {
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  &::placeholder {
    color: var(--gray20-alpha);
  }
}

.rs-picker-daterange.rs-picker-subtle .rs-input-group {
  height: 24px;
  outline: none;
}

.rs-picker-daterange.rs-picker-subtle .rs-input-group:hover {
  background-color: var(--gray5);
}

.rs-picker-daterange.rs-picker-subtle .rs-input-group:active {
  background-color: var(--gray20-alpha);
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: var(--primary-blue5) !important;
  background-color: var(--primary-blue50) !important;
}

.rs-calendar-table-cell-content {
  font-size: 14px !important;
}

.rs-picker-toolbar {
  display: flex;
}

.rs-btn.rs-btn-primary {
  font-size: 16px;
  line-height: 1.25;
  font-weight: bold;
  padding: 8px 16px;
}

.rs-btn-icon > .rs-icon {
  height: 16px !important;
  width: 16px !important;
  color: var(--gray60-alpha);
}

.rs-picker-caret-icon.rs-icon {
  height: 16px !important;
  width: 16px !important;
  color: var(--gray100);
}

.rs-input-group.rs-input-group-inside .rs-input-group-addon {
  padding: 0; // カレンダーアイコンの余白を削除
}

.rs-btn-close:hover {
  svg path {
    stroke: var(--primary-blue50); // クリアアイコンホバー時の色変更
  }
}
