@import 'styles/texts';

.primary {
  color: var(--primary-blue50);
}

.secondary {
  color: var(--secondary-green50);
}

.danger {
  color: var(--red50);
}

.basic {
  color: var(--gray90-alpha);
}

.white {
  color: var(--white100);
}
