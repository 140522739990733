@import 'styles/texts';
@import 'styles/libs';

$side-menu-width: 256px;

.base {
  position: fixed;
  top: 0;
  z-index: var(--z-index-side-menu-sp);
  display: none;
  width: 100%;
  height: 100vh; /* dvhが使えないブラウザでのフォールバック用 */
  height: calc-dvh(100); /* iOSなどでアドレスバーで隠れる領域を考慮した最大の高さ指定 */
}

.overlay {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
}

.contentWrapper {
  position: absolute;
  z-index: 2;
  width: $side-menu-width;
  height: 100%;
}

.content {
  position: relative;
  display: grid;
  grid-template-rows: minmax(0, max-content) auto;
  height: 100%;
  padding: 24px 12px;
  background: var(--gray100);
}

.closeSideMenuButton {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 10px;
  color: var(--white100);
}

.list {
  display: grid;
  row-gap: 8px;
  overflow-y: auto;
}

.item {
  display: flex;
  column-gap: 10px;
  align-items: center;
  padding: 12px 16px;

  &:hover {
    text-decoration: none;
  }
}

.inactive {
  @include typography('dense', 14px, normal);

  color: var(--white60);

  &:hover {
    color: var(--white100);
  }
}

.active {
  @include typography('dense', 14px, bold);

  color: var(--white100);
}

.support {
  display: flex;
  align-items: flex-end;
  padding: 12px;
}

.supportLink {
  @include typography('dense', 12px, normal);

  color: var(--gray5);

  &:hover {
    color: var(--white100);
  }
}

// react-transition-group 用のスタイル
// - https://reactcommunity.org/react-transition-group/css-transition

@mixin hidden {
  .overlay {
    opacity: 0;
  }

  .contentWrapper {
    left: -100%;
  }
}

@mixin shown {
  .overlay {
    opacity: 1;
  }

  .contentWrapper {
    left: 0;
  }
}

@mixin transition-set {
  .overlay {
    transition: all 250ms;
  }

  .contentWrapper {
    transition: all 250ms;
  }
}

// enter → enterActive → enterDone
.enter,
.enterActive,
.enterDone {
  display: block;
}

.enter {
  @include hidden;
}

.enterActive {
  @include shown;
  @include transition-set;
}

// exit → exitActive → exitDone
.exit,
.exitActive {
  display: block;
}

.exit {
  @include shown;
}

.exitActive {
  @include hidden;
  @include transition-set;
}
