@import 'styles/texts';

.base {
  position: fixed;
  top: 0;
  z-index: var(--z-index-scrim);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  /* stylelint-disable-next-line */
  background: rgba(57, 57, 58, 0.9);
}

.container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.text {
  @include typography('normal', 16px, bold);

  margin-top: 16px;
  color: var(--white100);
}
