@import 'styles/texts';

.base {
  display: inline-flex;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
  min-width: max-content;

  &.active,
  &:hover {
    text-decoration: none;
  }
}

.iconWrapper {
  display: flex;
}

.medium {
  @include typography('tight', 14px, bold);

  > .iconWrapper {
    & > svg {
      width: 21px;
      height: 21px;
    }
  }

  &.normal {
    height: 48px;
    padding: 0 16px;
    border-radius: 4px;
  }
}

.small {
  @include typography('tight', 12px, bold);

  > .iconWrapper {
    & > svg {
      width: 18px;
      height: 18px;
    }
  }

  &.normal {
    height: 36px;
    padding: 0 12px;
    border-radius: 4px;
  }
}

.normal {
  &.primary {
    color: var(--white100);
    background: var(--primary-blue50);

    &.active,
    &:hover {
      background: var(--primary-blue60);
    }
  }

  &.secondary {
    color: var(--white100);
    background: var(--secondary-green50);

    &.active,
    &:hover {
      background: var(--secondary-green60);
    }
  }

  &.danger {
    color: var(--white100);
    background: var(--red50);

    &.active,
    &:hover {
      background: var(--red60);
    }
  }

  &.outlinePrimary {
    color: var(--primary-blue50);
    background: var(--white100);
    border: 1px solid var(--primary-blue50);

    &.active,
    &:hover {
      background: var(--primary-blue5);
    }
  }

  &.outlineSecondary {
    color: var(--secondary-green50);
    background: var(--white100);
    border: 1px solid var(--secondary-green50);

    &.active,
    &:hover {
      /* stylelint-disable-next-line */
      background: rgba(10, 207, 151, 0.08);
    }
  }

  &.outlineDanger {
    color: var(--red50);
    background: var(--white100);
    border: 1px solid var(--red50);

    &.active,
    &:hover {
      /* stylelint-disable-next-line */
      background: rgba(250, 92, 124, 0.08);
    }
  }

  &.basic {
    color: var(--gray90-alpha);
    background: var(--white100);
    border: 1px solid var(--gray20);

    &.active,
    &:hover {
      color: var(--gray100);
      background: var(--gray10);
    }
  }

  &.disable {
    color: var(--white100);
    background: var(--gray20);

    &.active,
    &:hover {
      background: var(--gray20);
    }
  }

  &.basicDisable {
    color: var(--gray30);
    background: var(--white100);
    border: 1px solid var(--gray10);

    &.active,
    &:hover {
      background: var(--white100);
    }
  }
}

.skeleton {
  &.primary {
    color: var(--primary-blue50);

    &.active,
    &:hover {
      color: var(--primary-blue60);
    }
  }

  &.secondary {
    color: var(--secondary-green50);

    &.active,
    &:hover {
      color: var(--secondary-green60);
    }
  }

  &.basic {
    color: var(--gray100);
  }

  &.danger {
    color: var(--red50);

    &.active,
    &:hover {
      color: var(--red60);
    }
  }

  &.disable {
    color: var(--gray20);
    opacity: 0.5;
  }
}

// iconOnlyButton
.iconOnlyButtonBase {
  display: flex;
  color: var(--gray90-alpha);
  border-radius: 4px;

  &.medium {
    padding: 12px;

    & > svg {
      width: 24px;
      height: 24px;
    }
  }

  &.small {
    padding: 8px;

    & > svg {
      width: 20px;
      height: 20px;
    }
  }

  &.active,
  &:hover {
    background-color: var(--gray10-alpha);
  }

  &:disabled {
    color: var(--gray30);
    background-color: unset;
  }
}
