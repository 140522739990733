@import 'styles/texts';
@import 'styles/three-point-reader';

.base {
  position: relative;
  z-index: var(--z-index-base);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 40px;
  background: var(--white100);
  box-shadow: var(--main-shadow);
}

.menuItem {
  display: none;
}

.menuButton {
  display: flex;
  align-items: center;
}

.menuIcon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.title {
  @include typography('dense', 16px, bold);

  color: var(--gray100);
}

.account {
  display: flex;
  align-items: center;
  color: var(--gray60-alpha);

  &:hover {
    opacity: 0.5;
  }
}

.accountName {
  @include typography('dense', 14px, normal);
  @include three-point-reader(1);

  max-width: 250px;
  margin-left: 4px;
}

.listItem {
  @include typography('normal', 14px, normal);
}
