@import 'styles/texts';
@import 'styles/three-point-reader';

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background: var(--gray100);
}

.menuButton {
  display: flex;
}

.menuIcon {
  width: 24px;
  height: 24px;
  color: var(--white100);
}

.logo {
  height: 22px;
}

.titleWrapper {
  display: flex;
  column-gap: 16px;
  align-items: center;
  padding: 12px 16px;
  background: var(--white100);
}

.backLink {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: inherit;
}

.title {
  @include typography('normal', 16px, bold);

  flex-grow: 1;
}

.account {
  display: flex;
  align-items: center;
  color: var(--white100);
}

.accountName {
  @include typography('normal', 12px, normal);

  min-width: auto;
  max-width: 250px;
  color: var(--gray60-alpha) !important;
}

.listItem {
  @include typography('normal', 14px, normal);

  height: 40px;
  padding: 0 8px;

  &.accountName {
    @include typography('normal', 12px, normal);

    height: auto;
    min-height: 40px;
    padding: 8px;
  }
}
